

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import * as api from './api'
import VueLazyload from 'vue-lazyload'

import VueI18n from 'vue-i18n'
import zhCN from './i18n/zh-CN'
import enUS from './i18n/en-US'


Vue.use(VueLazyload)

Vue.use(VueI18n)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$api = api // 将api挂载到vue的原型中

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'zh-CN', // 默认语言为中文
  // locale: 'zh-CN', // 默认语言为中文
  messages: {
    'zh-CN': zhCN,
    'en-US': enUS
  }
})


router.beforeEach((to, from, next) => {
  document.title = '智慧物联实验室'
  if (to.path === '/en') {
    store.state.language = 'en-US'
    document.title = "Dr. Tie Qiu's Homepage"
    next()
  } else if (store.state.language === 'en-US' && !to.query.lang) {
    next({
      path: to.path,
      query: {
        lang: 'en-US',
      },
    })
  } else {
    next()
  }
})


new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
