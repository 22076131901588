import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/Home',
      name: 'Home',
      component: () => import('@/views/lab/Home.vue'),
      meta: {
        keepAlive: true, // 需要缓存
        title: '智慧物联实验室' 
      },
    },
    {
      path: '/en',
      name: 'enHome',
      component: () => import('@/views/Home.vue'),
      meta: {
        keepAlive: true, // 需要缓存
      },
    },
    {
      path: '/PictureFall',
      name: 'PictureFall',
      component: () => import('./views/lab/PictureFall.vue'),
      meta: {
        keepAlive: false, // 不需要缓存
      },
    },
    {
      path: '/Achievement',
      name: 'Achievement',
      component: () => import('@/views/lab/Achievement.vue'),
      meta: {
        keepAlive: false, // 不需要缓存
      },
    },
    {
      path: '/Trends',
      name: 'Trends',
      component: () => import('@/views/lab/Trends.vue'),
      meta: {
        keepAlive: false, // 不需要缓存
      },
    },
    {
      path: '/AchievementDetail',
      name: 'AchievementDetail',
      component: () => import('@/views/lab/AchievementDetail.vue'),
      meta: {
        keepAlive: false, // 不需要缓存
      },
    },
    {
      path: '/Team',
      name: 'Team',
      component: () => import('@/views/lab/Team.vue'),
      meta: {
        keepAlive: false, // 不需要缓存
      },
    },
    {
      path: '/News',
      name: 'News',
      component: () => import('./views/lab/News.vue'),
      meta: {
        keepAlive: false, // 不需要缓存
      },
    },
    {
      path: '/NewsDetail',
      name: 'NewsDetail',
      component: () => import('./views/lab/NewsDetail.vue'),
      meta: {
        keepAlive: false, // 不需要缓存
      },
    },
    {
      path: '/TrendsDetail',
      name: 'TrendsDetail',
      component: () => import('./views/lab/TrendsDetail.vue'),
      meta: {
        keepAlive: false, // 不需要缓存
      },
    },
    {
      path: '/Research',
      name: 'Research',
      component: () => import('@/views/lab/Research.vue'),
      meta: {
        keepAlive: false, // 不需要缓存
      },
    },
    {
      path: '/Enrollment',
      name: 'Enrollment',
      component: () => import('./views/lab/Enrollment.vue'),
      meta: {
        keepAlive: false, // 不需要缓存
      },
    },
    {
      path: '/*',
      redirect: '/Home',
    },
  ],
})
